import React from 'react'
import {_t, ConfigController, NotifyController} from "../helpers/provider";

export default class Panel extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            statusMap: {
                created: {
                    label: _t("Stworzone"),
                    color: "#bfa064"
                },
                during: {
                    label: _t("W trakcie"),
                    color: "#e46b62"
                },
                ended: {
                    label: _t("Zakończone"),
                    color: "#1f8055"
                }
            }
        }
    }

    componentDidMount() {
        this.get()
    }

    get() {
        fetch(ConfigController.get("API_URL") + '/getPatient',
            {
                method: 'post',
                body: JSON.stringify({})
            }).then(response => response.json())
            .then(response => {
                this.setState({
                    data: response.patients
                })
            })
            .catch(error => console.log(error)
            );
    }

    remove(patient) {
        fetch(ConfigController.get("API_URL") + '/removePatient',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body: JSON.stringify({
                    roomId: patient.roomId
                })
            }).then(response => response.json())
            .then(response => {
                this.get()
            })
            .catch(error => console.log(error)
            );
    }

    render() {
        return (
            <div className={"app-panel-container"}>
                <div className={"window-upper-beam"}>
                    <div className={"window-logo"}>
                        <img src={require("../assets/logo.png")} alt=""/>
                    </div>

                    <div onClick={() => this.props.close()} className={"close-window"}>
                        <svg height="365pt" viewBox="0 0 365.71733 365" width="365pt" xmlns="http://www.w3.org/2000/svg"><g><path d="m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0"/><path d="m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0"/></g></svg>
                        <span>{_t("zamknij")}</span>
                    </div>
                </div>

                <div className={"panel-content-wrapper"}>
                    <div className={"panel-table"}>
                        <table>
                            <tr>
                                <th className={"patientRow"}>{_t("Pacjent")}</th>
                                <th>{_t("Email")}</th>
                                <th>{_t("Id spotkania")}</th>
                                <th>{_t("Video")}</th>
                                <th>{_t("Audio")}</th>
                                <th>{_t("Czas")}</th>
                                <th>{_t("Spotkanie")}</th>
                                {/*<th>{_t("Status")}</th>*/}
                                <th>{_t("Usuń")}</th>
                            </tr>
                            {Object.keys(this.state.data).map((key, index) => {
                                return (
                                    <tr>
                                        <td>{this.state.data[key].name} {this.state.data[key].surname}</td>
                                        <td>{this.state.data[key].email}</td>
                                        <td>{this.state.data[key].roomId}</td>



                                        {(this.state.data[key].uploadStatus && this.state.data[key].uploadStatus.pathAudio) ? <td className={"current-status"}><a href={ConfigController.get("SERVER_URL") + this.state.data[key].uploadStatus.pathAudio.replace(".", "")} target={"_blank"} download={`{(this.state.data[key].name && this.state.data[key].surname) ? this.state.data[key].name + "_" + this.state.data[key].surname : this.state.data[key].roomId}` + ".mp3"}>Pobierz</a></td> : <td></td>}
                                        {(this.state.data[key].uploadStatus && this.state.data[key].uploadStatus.pathVideo) ? <td className={"current-status"}><a href={ConfigController.get("API_URL") + this.state.data[key].uploadStatus.pathVideo.replace(".", "")} target={"_blank"} download={`{(this.state.data[key].name && this.state.data[key].surname) ? this.state.data[key].name + "_" + this.state.data[key].surname : this.state.data[key].roomId}` + ".mp4"}>Pobierz</a></td> : <td></td>}

                                        <td>{this.state.data[key].time}</td>
                                        <td><a href={`/admin/${this.state.data[key].roomId}`}>{_t("Otwórz")}</a></td>
                                        {/*<td style={{*/}
                                            {/*background: this.state.statusMap[this.state.data[key].status].color*/}
                                        {/*}} className={"status"}>{this.state.statusMap[this.state.data[key].status].label}</td>*/}
                                        <td style={{cursor: "pointer"}} onClick={() => {
                                            if (window.confirm(_t("Czy na pewno chcesz usunąć ten rekord?"))) {
                                                this.remove(this.state.data[key])
                                            }
                                        }}>{_t("Usuń")}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
