import Config from "./config";
import Notify from "./notify";
import Timer from "./timer";
import translationController from "./translate";

export let ConfigController
export let NotifyController
export let TimerController
export let TranslationController
export let _t

export const initController = (appController) => {
    ConfigController = new Config()

    ConfigController.set("API_URL", "http://video.wk-api.pl/video")
    ConfigController.set("SERVER_URL", "http://video.wk-api.pl/")

    TranslationController = new translationController(appController)
    TranslationController.getDictionary()
        .then((r) => {
            if (r) {
                _t = word => {
                    return TranslationController.translate(word)
                }

                appController.setState({appReady: true})
            }
        })

    NotifyController = new Notify()
    TimerController = new Timer()
}
