import React from "react"
import {NotifyController} from "../helpers/provider";

export default class ScreenNotify extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            notify: null
        }

        NotifyController.initOnScreen(this)
    }

    render() {
        return (
            this.state.notify ?
            <div className={"app-screen-notify"}>
                <div className={"app-screen-bg"}></div>

                <div className={"asn-content"}>
                    <svg version="1.1" id="Layer_1" x="0px" y="0px"
                         viewBox="0 0 286.054 286.054">
                        <g>
                            <path d="M168.352,142.924l25.28-25.28c3.495-3.504,3.495-9.154,0-12.64l-12.64-12.649
		c-3.495-3.486-9.145-3.495-12.64,0l-25.289,25.289l-25.271-25.271c-3.504-3.504-9.163-3.504-12.658-0.018l-12.64,12.649
		c-3.495,3.486-3.486,9.154,0.018,12.649l25.271,25.271L92.556,168.15c-3.495,3.495-3.495,9.145,0,12.64l12.64,12.649
		c3.495,3.486,9.145,3.495,12.64,0l25.226-25.226l25.405,25.414c3.504,3.504,9.163,3.504,12.658,0.009l12.64-12.64
		c3.495-3.495,3.486-9.154-0.009-12.658L168.352,142.924z M143.027,0.004C64.031,0.004,0,64.036,0,143.022
		c0,78.996,64.031,143.027,143.027,143.027s143.027-64.031,143.027-143.027C286.054,64.045,222.022,0.004,143.027,0.004z
		 M143.027,259.232c-64.183,0-116.209-52.026-116.209-116.209s52.026-116.21,116.209-116.21s116.209,52.026,116.209,116.209
		S207.21,259.232,143.027,259.232z"/>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                    </svg>
                    <div className={"asc-info"}>{this.state.notify}</div>

                    <a href="/">Powrót</a>
                </div>
            </div> : <React.Fragment/>
        );
    }
}
