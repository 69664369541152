import {ConfigController, NotifyController} from "./provider";

function Config() {
    this.conf = {

    }
}

Config.prototype = {
    get: function (param) {
        if (param) {
            return this.conf[param]
        } else {
            return this.conf
        }
    },
    set: function (param, value) {
        this.conf[param] = value
    },
    getPatient: function () {
        if (!this.get("patientName") || !this.get("patientSurname") || !this.get("patientEmail")) {
            fetch(ConfigController.get("API_URL") + '/getPatient',
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    body: JSON.stringify({
                        roomId: this.get("roomId")
                    })
                }).then(response => response.json())
                .then(response => {
                    if (response.patient) {
                        const patientData = response.patient

                        this.set("patientName", patientData.name)
                        this.set("patientSurname", patientData.surname)
                        this.set("patientEmail", patientData.email)
                        this.set("patient", patientData)
                    } else {
                        NotifyController.set("Nie znaleziono spotkania. Spróbuj ponownie później lub zmień ID.", "error", true)
                    }
                })
                .catch(error => console.log(error)
                );
        }
    },
    forceGetPatient: function () {
        return fetch(ConfigController.get("API_URL") + '/getPatient',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body: JSON.stringify({
                    roomId: this.get("roomId")
                })
            }).then(response => response.json())
            .then(response => {
                if (response.patient) {
                    const patientData = response.patient

                    this.set("patientName", patientData.name)
                    this.set("patientSurname", patientData.surname)
                    this.set("patientEmail", patientData.email)
                    this.set("patient", patientData)

                    this.conf.chatComponent.setState({
                        refresh: true
                    })

                    if (this.conf.patient && this.conf.patient.uploadStatus && this.conf.patient.uploadStatus.error) {
                        setTimeout(() => {
                            this.forceGetPatient()
                        }, 3000)
                    }
                } else {
                    NotifyController.set("Nie znaleziono spotkania. Spróbuj ponownie później lub zmień ID.", "error", true)
                }
            })
            .catch(error => console.log(error)
            );
    },
    setStatus: function (status) {
        fetch(ConfigController.get("API_URL") + '/status',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body: JSON.stringify({
                    roomId: this.get("roomId"),
                    status
                })
            }).then(response => response.json())
            .then(response => {
            })
            .catch(error => console.log(error)
            );
    }
}

export default Config
