import React from 'react'
import VideoCall from '../helpers/simple-peer'
import '../styles/video.css'
import io from 'socket.io-client'
import Chat from "./chat";
import {_t, ConfigController, NotifyController, TimerController, TranslationController} from "../helpers/provider";
import Loader from "./loader";
import Timer from "react-compound-timer"

class Video extends React.Component {
  constructor() {
    super()

      this.localVideo = null

    this.state = {
      localStream: {},
      remoteStreamUrl: '',
      streamUrl: '',
      initiator: false,
      peer: {},
      full: false,
      connecting: false,
      waiting: true
    }
  }
  videoCall = new VideoCall()
  componentDidMount() {
    import("../main.js")

    const startBtn = document.getElementById("start")
    setTimeout(() => {
      startBtn.click()
    }, 500)

    const socket = io("http://video.wk-api.pl", {
        path: "/video/socket.io"
    })
    const component = this
    this.setState({ socket })
    const { roomId, admin } = this.props.match.params

    if (admin) {
      ConfigController.set("isAdmin", true)
    }

    ConfigController.set("socket", socket)
    ConfigController.set("roomId", roomId)
      ConfigController.set("videoComponent", component)
    ConfigController.getPatient()

      this.getUserMedia().then(() => {
      socket.emit('join', { roomId: roomId })
    })
    socket.on('init', () => {
      ConfigController.set("isInitiator", true)
      component.setState({ initiator: true })
    })
    socket.on('ready', (data) => {
      ConfigController.set("chatMsg", data.chatMsg)
      ConfigController.set("videoReady", true)
        ConfigController.set("isDisconnected", false)
        ConfigController.setStatus("during")
      component.enter(roomId)
    })
    socket.on('desc', data => {
      if (data.type === 'offer' && component.state.initiator) return
      if (data.type === 'answer' && !component.state.initiator) return
      component.call(data)
    })
    socket.on('disconnected', () => {
        console.log("disconnected")
      component.setState({ initiator: true })
        ConfigController.set("isDisconnected", true)
        ConfigController.setStatus("ended")
    })
    socket.on('full', () => {
      component.setState({ full: true })
    })
  }

  getUserMedia(cb) {
    return new Promise((resolve, reject) => {

      navigator.getUserMedia = (
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia
      );

      const op = {
        video: {
          width: { min: 160, ideal: 640, max: 1280 },
          height: { min: 120, ideal: 360, max: 720 }
        },
        audio: true
      }

      if (this.localVideo) {
          navigator.getUserMedia(
              op,
              stream => {
                  this.setState({ streamUrl: stream, localStream: stream })
                  this.localVideo.srcObject = stream
                  resolve()
              },
              () => {}
          )
      }

    })
  }
  enter = roomId => {
    this.setState({ connecting: true })
    const peer = this.videoCall.init(
      this.state.localStream,
      this.state.initiator
    )
    peer.on('signal', data => {
        console.log("signal")
      const signal = {
        room: roomId,
        desc: data
      }
      this.state.socket.emit('signal', signal)
    })
    peer.on('stream', stream => {
      window.stream = stream
      this.remoteVideo.srcObject = stream
      this.setState({ connecting: false, waiting: false })
    })
    peer.on('error', function(err) {
      console.log(err)
    })
  }
  call = otherId => {
    this.videoCall.connect(otherId)
  }
  renderFull = () => {
    if (this.state.full) {
      return NotifyController.set(_t("Ten pokój jest pełny! Załóż inny lub poczekaj kilkanaście sekund i odśwież."), "error", true)
    }
  }
  render() {
    return (
      <div className="video-wrapper">
        <div className={"window-upper-beam"}>
          <div className={"window-logo"}>
            <img src={require("../assets/logo.png")} alt=""/>
          </div>

            <div className={"window-details"}>
                {ConfigController.get("isAdmin") &&
                    <div className={"patient-details"}>
                        {(ConfigController.get("patientName") || ConfigController.get("patientSurname")) &&
                        <div
                            className={"patient-detail patient-name"}>{_t("Uczestnik")}: <span>{ConfigController.get("patientName")} {ConfigController.get("patientSurname")}</span>
                        </div>
                        }
                        {ConfigController.get("patientEmail") &&
                        <React.Fragment>
                            <div
                                className={"patient-detail patient-email"}>Email <span>{ConfigController.get("patientEmail")}</span>
                            </div>
                            <div onClick={() => NotifyController.sendMail()} className={"email-trigger"}>{_t("wyślij powiadomienie")}</div>
                        </React.Fragment>
                        }
                    </div>
                }
                <div className={"window-langs"}>
                    <div onClick={() => TranslationController.setLang("pl")} className={"lang"}>
                        <img src={require("../assets/pl.gif")} alt=""/>
                    </div>
                    <div onClick={() => TranslationController.setLang("en")} className={"lang"}>
                        <img src={require("../assets/en.gif")} alt=""/>
                    </div>
                </div>
            </div>
        </div>

        <div className={"window-aligner"}>
          <div className={"video-container"}>

            <div className="local-video-wrapper">
              <video
                  autoPlay
                  id="localVideo"
                  muted
                  ref={video => (this.localVideo = video)}
              />

              <video id="gum" playsinline autoPlay muted></video>
              <video id="recorded" playsinline loop></video>
            </div>

            <div>
              <span id="errorMsg"></span>
            </div>

            <div className={"video-box"}>
                {ConfigController.get("isAdmin") &&
                <div className={"video-timer"}>
                    <Timer
                        formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                        startImmediately={false}
                    >
                        {({ start, resume, pause, stop, reset, timerState, getTime }) => {
                            TimerController.startFn = start
                            TimerController.pauseFn = pause
                            TimerController.state = getTime()

                            return (
                                <React.Fragment>
                                    <Timer.Hours />:
                                    <Timer.Minutes />:
                                    <Timer.Seconds />
                                </React.Fragment>
                            )
                        }}
                    </Timer>
                </div>
                }

              <video
                  autoPlay
                  className={`${
                      this.state.connecting || this.state.waiting ? 'hide' : ''
                      }`}
                  id="remoteVideo"
                  ref={video => (this.remoteVideo = video)}
              />
              <div className={"video-controls"}>
                <div className={"controls"}>
                    <button style={{
                    display: "none"
                  }} id="start">
                    <div className={"btn-icon"}>
                      <img src={require("../assets/photo-camera.png")} alt=""/>
                    </div>
                  </button>
                    <React.Fragment>
                        <button style={{
                            visibility: ConfigController.get("isAdmin") ? "visible" : "hidden"
                        }} id="record" disabled>
                            <div className={"btn-icon"}>
                                <svg style={{width: 35}} id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m384.52 309.185c31.906 0 57.864-25.958 57.864-57.864s-25.958-57.863-57.864-57.863-57.864 25.957-57.864 57.863c-.001 31.906 25.957 57.864 57.864 57.864zm0-85.728c15.364 0 27.864 12.499 27.864 27.863s-12.5 27.864-27.864 27.864-27.864-12.5-27.864-27.864c-.001-15.364 12.499-27.863 27.864-27.863z"/><path d="m85.933 294.846c4.821 0 9.642-1.978 9.642-5.934v-29.79h8.159l16.935 32.881c1.359 2.596 3.708 3.584 6.181 3.584 5.686 0 12.113-5.191 12.113-10.012 0-.618-.123-1.483-.618-2.226l-15.08-27.318c8.652-3.337 14.957-11.248 14.957-25.093 0-20.149-13.474-26.453-30.408-26.453h-25.589c-3.585 0-5.934 2.72-5.934 5.811v78.616c0 3.956 4.821 5.934 9.642 5.934zm9.641-73.549h12.238c6.922 0 11.125 2.843 11.125 11.496 0 8.652-4.203 11.496-11.125 11.496h-12.238z"/><path d="m156.888 294.846h44.005c4.079 0 5.811-4.326 5.811-8.282 0-4.573-2.102-8.529-5.811-8.529h-33.128v-21.014h18.542c4.079 0 5.81-3.955 5.81-7.293 0-3.956-2.102-7.54-5.81-7.54h-18.542v-20.891h33.128c3.709 0 5.811-3.955 5.811-8.529 0-3.955-1.731-8.282-5.811-8.282h-44.005c-4.203 0-8.406 1.979-8.406 5.934v78.493c-.001 3.955 4.203 5.933 8.406 5.933z"/><path d="m242.921 295.587c17.677 0 31.026-9.394 31.026-25.834 0-7.417-3.09-9.147-9.765-9.147-4.944 0-9.147 1.236-9.518 5.81-.37 4.821-.988 12.361-10.878 12.361-7.169 0-11.248-3.956-11.248-12.361v-32.757c0-8.405 4.079-12.361 11.125-12.361 10.507 0 10.878 7.911 11.001 11.125.247 4.45 4.45 5.809 9.642 5.809 6.552 0 9.642-1.73 9.642-9.147 0-15.698-13.35-24.599-31.026-24.599-16.192 0-29.666 7.911-29.666 29.173v32.757c-.001 21.26 13.473 29.171 29.665 29.171z"/><path d="m485 129.391h-458c-14.888 0-27 12.112-27 27v199.219c0 14.888 12.112 27 27 27h458c14.888 0 27-12.112 27-27v-199.219c0-14.888-12.112-27-27-27zm-3 223.218h-452v-193.218h452z"/></svg>
                            </div>
                        </button>
                        <button style={{
                            visibility: ConfigController.get("isAdmin") ? "visible" : "hidden"
                        }} id="pause" disabled>
                            <div className={"btn-icon"}>
                                <svg style={{width: 15}} version="1.1" id="Capa_1"  x="0px" y="0px"
                                     width="438.536px" height="438.536px" viewBox="0 0 438.536 438.536"
                                     >
<g>
	<g>
		<path d="M164.453,0H18.276C13.324,0,9.041,1.807,5.425,5.424C1.808,9.04,0.001,13.322,0.001,18.271v401.991
			c0,4.948,1.807,9.233,5.424,12.847c3.619,3.617,7.902,5.428,12.851,5.428h146.181c4.949,0,9.231-1.811,12.847-5.428
			c3.617-3.613,5.424-7.898,5.424-12.847V18.271c0-4.952-1.807-9.231-5.428-12.847C173.685,1.807,169.402,0,164.453,0z"/>
        <path d="M433.113,5.424C429.496,1.807,425.215,0,420.267,0H274.086c-4.949,0-9.237,1.807-12.847,5.424
			c-3.621,3.615-5.432,7.898-5.432,12.847v401.991c0,4.948,1.811,9.233,5.432,12.847c3.609,3.617,7.897,5.428,12.847,5.428h146.181
			c4.948,0,9.229-1.811,12.847-5.428c3.614-3.613,5.421-7.898,5.421-12.847V18.271C438.534,13.319,436.73,9.04,433.113,5.424z"/>
	</g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
                                    <g>
</g>
</svg>
                            </div>
                        </button>
                        <button style={{
                            display: "none",
                            visibility: ConfigController.get("isAdmin") ? "visible" : "hidden"
                        }} id="play" disabled>
                            <div className={"btn-icon"}>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                     viewBox="0 0 310 310"  >
                                    <g id="XMLID_822_">
                                        <path id="XMLID_823_" d="M297.917,64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359,0-61.369,5.776-72.517,19.938
		C0,79.663,0,100.008,0,128.166v53.669c0,54.551,12.896,82.248,83.386,82.248h143.226c34.216,0,53.176-4.788,65.442-16.527
		C304.633,235.518,310,215.863,310,181.835v-53.669C310,98.471,309.159,78.006,297.917,64.645z M199.021,162.41l-65.038,33.991
		c-1.454,0.76-3.044,1.137-4.632,1.137c-1.798,0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764
		c0-3.492,1.822-6.732,4.808-8.546c2.987-1.814,6.702-1.938,9.801-0.328l65.038,33.772c3.309,1.718,5.387,5.134,5.392,8.861
		C204.394,157.263,202.325,160.684,199.021,162.41z"/>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </div>
                        </button>
                        <button style={{
                            visibility: ConfigController.get("isAdmin") ? "visible" : "hidden",
                            display: "none"
                        }} id="download" disabled>
                            <div className={"btn-icon"}>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                     width="433.5px" height="433.5px" viewBox="0 0 433.5 433.5"
                                >
                                    <g>
                                        <g id="file-download">
                                            <path d="M395.25,153h-102V0h-153v153h-102l178.5,178.5L395.25,153z M38.25,382.5v51h357v-51H38.25z"/>
                                        </g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </div>
                        </button>
                        <button className={"end-btn"} id={"endStream"}>{_t("Zakończ")}</button>
                        <button style={{
                            display: "none"
                        }} id={"resetStatus"}>Reset</button>
                    </React.Fragment>
                  <div style={{display: "none"}} className={"ctrl-options"}>
                    <label htmlFor="echoCancellation">Redukcja echa</label>
                    <input type="checkbox" id="echoCancellation"/>
                  </div>
                </div>
              </div>
            </div>

            {this.state.connecting && (
                <div className="status">
                  <p>{_t("Nawiązuję połączenie...")}</p>
                </div>
            )}
            {this.state.waiting && (
                <div className="status">
                  <Loader/>
                  <p>{_t("Oczekuję na połączenie...")}</p>
                </div>
            )}

            {this.renderFull()}
          </div>

          <div className={"chat-container"}>
            {ConfigController.get("socket") ?
            <Chat/> : <Loader/>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Video
