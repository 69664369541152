import {ConfigController, NotifyController} from "./provider";

function Notify() {
    this.notify = []
    this.provider = null
    this.onScreenProvider = null
}

Notify.prototype = {
    set: function (text, level = "error", pernament) {
        this.notify.push({
            text, level
        })

        this.provider.setState({refresh: true})

        if (!pernament) {
            setTimeout(() => this.clear(), 2000)
        }
    },
    clear: function () {
        this.notify.pop()
        this.provider.setState({refresh: true})
    },
    get: function () {
        return this.notify
    },
    init: function (provider) {
        this.provider = provider
    },
    initOnScreen: function (provider) {
        this.onScreenProvider = provider
    },
    setScreen: function (text) {
        this.onScreenProvider.setState({
            notify: text
        })
    },
    sendMail: function () {
        if (window.confirm("Czy na pewno chcesz wysłać wiadomość email do " + ConfigController.get("patientEmail") + "?")) {
            fetch(ConfigController.get("API_URL") + '/sendMail',
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    body: JSON.stringify({
                        email: ConfigController.get("patientEmail")
                    })
                }).then(response => response.json())
                .then(response => {
                    NotifyController.set("Wysłano powiadomienie.", "success")
                })
                .catch(error => console.log(error)
                );
        }
    }
}

export default Notify
