import {ConfigController} from "./provider";

export default function translationController(appController) {
    this.appController = appController
    this.dictionary = null
    this.lang = "pl"
}

translationController.prototype = {
    setLang: function (lang) {
        this.lang = lang
        this.appController.setState({
            refresh: true
        })
    },
    setToDictionary: function (word) {
        return fetch(ConfigController.get("API_URL") + '/setDictionary',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'post',
                body: JSON.stringify({
                    word
                })
            }).then(response => response.json())
            .then(response => {
                this.getDictionary()
            })
            .catch(error => {
                }
            );
    },
    getDictionary: function () {
        return fetch(ConfigController.get("API_URL") + '/getDictionary',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'get'
            }).then(response => response.json())
            .then(response => {
                this.dictionary = response.dictionary

                return true
            })
            .catch(error => {
                }
            );
    },
    translate: function (word) {
        if (this.lang === "pl") {
            return word
        }

        if (this.dictionary[word] && this.dictionary[word].length > 0) {
            return this.dictionary[word]
        } else {
            if (!(word in this.dictionary)) {
                this.setToDictionary(word)
                console.log(word, "word")
                return word
            } else {
                return word
            }
        }
    }
}
