import React from "react"
import {_t, ConfigController} from "../helpers/provider";
import Loader from "./loader";

export default class Chat extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            msg: null,
        }

        this.messagesEnd = React.createRef()

        ConfigController.set("chatComponent", this)
    }

    componentDidMount() {
        ConfigController.get("socket").on('chatMsg', (data) => {
            ConfigController.set("chatMsg", data.chatMsg[ConfigController.get("roomId")])
            ConfigController.get("chatComponent").setState({
                refresh: true
            })
            this.scrollToBottom()
        })
    }

    scrollToBottom = () => {
        if (this.messagesEnd.current) {
            let objDiv = document.getElementById("msg_list");
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    sendMessage() {
        if (ConfigController.get("videoReady")) {
            let objDiv = document.getElementById("msg_list");
            objDiv.scrollTop = objDiv.scrollHeight;

            const socket = ConfigController.get("socket")

            socket.emit("chatMsg", {
                value: this.state.msg,
                roomId: ConfigController.get("roomId"),
                isInitiator: ConfigController.get("isInitiator") || false
            })

            this.setState({
                msg: ""
            })

            setTimeout(() => {
                this.scrollToBottom()
            }, 100)
        }
    }

    sendFile(e) {
        const ipt = document.getElementById("chat-file")
        const file = ipt.files[0]

        const fd = new FormData()
        fd.append("fpl", file, file.name)

        fetch(ConfigController.get("API_URL") + '/chatFile',
            {
                method: 'post',
                body: fd
            }).then(response => response.json())
            .then(response => {
                const uploaded = response.uploaded

                console.log(uploaded, "uploaded")

                this.setState({
                    msg: "is_file_s89_" + ConfigController.get("API_URL") + "/static/" + uploaded.filename
                })

                this.sendMessage()
            })
            .catch(error => console.log(error)
            );

        console.log(file, "file")
    }

    render() {
        const messages = ConfigController.get("chatMsg")

        if (messages) {
            this.scrollToBottom()
        }

        console.log(ConfigController.get().patient, "STATUS")

        return (
            <div className={"video-chat"}>
                <div style={{
                    minHeight: 160
                }} className={"chat-box"}
                >
                    {ConfigController.get("isAdmin") && ConfigController.get().patient && ConfigController.get().patient.uploadStatus && (
                        ConfigController.get().patient.uploadStatus.progress == 100 ?
                            <div className={"status-box"}>
                                <div className={"main-status"}>{_t("Pliki gotowe do pobrania")}</div>
                                <div className={"status-box"}>
                                    <div className={"current-status"}><span>Audio:</span> <a href={ConfigController.get("SERVER_URL") + ConfigController.get().patient.uploadStatus.pathAudio.replace(".", "")} target={"_blank"} download={`${(ConfigController.get().patient.name && ConfigController.get().patient.surname) ? ConfigController.get().patient.name + "_" + ConfigController.get().patient.surname : ConfigController.get().patient.roomId}` + ".mp3"}>{_t("Pobierz")} <span>({ConfigController.get().patient.time})</span></a></div>
                                    <div className={"current-status"}><span>Video:</span> <a href={ConfigController.get("API_URL") + ConfigController.get().patient.uploadStatus.pathVideo.replace(".", "")} target={"_blank"} download={`${(ConfigController.get().patient.name && ConfigController.get().patient.surname) ? ConfigController.get().patient.name + "_" + ConfigController.get().patient.surname : ConfigController.get().patient.roomId}` + ".mp4"}>{_t("Pobierz")} <span>({ConfigController.get().patient.time})</span></a></div>
                                </div>
                            </div>
                            :
                            <div className={"status-box"}>
                                <div className={"main-status"}>{_t("Trwa przetwarzanie plików")}</div>
                                <div className={"loader-box"}>
                                    <Loader/>
                                </div>
                                <div className={"status-box"}>
                                    <div className={"progress"}>{ConfigController.get().patient.uploadStatus.progress}%</div>
                                    <div className={"current-status"}><span>Status:</span>
                                        {ConfigController.get().patient.uploadStatus.error ?
                                        <div>
                                            <span style={{color: "red"}}>Nieoczekiwany błąd</span>
                                            <div>CMD: {ConfigController.get().patient.uploadStatus.status.cmd}</div>
                                            <button onClick={() => {
                                                ConfigController.forceGetPatient()

                                                return fetch(ConfigController.get("API_URL") + '/runffpeg',
                                                    {
                                                        headers: {
                                                            'Accept': 'application/json',
                                                            'Content-Type': 'application/json'
                                                        },
                                                        method: 'post',
                                                        body: JSON.stringify({
                                                            roomId: ConfigController.get("roomId"),
                                                        })
                                                    }).then(response => response.json())
                                                    .then(response => {
                                                    })
                                                    .catch(error => console.log(error)
                                                    );
                                            }
                                            }>Spróbuj ponownie</button>
                                        </div>
                                         : _t(ConfigController.get().patient.uploadStatus.status)}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className={"leave-box"}>
                        <a href={"https://www.e-psyche.eu/pl/"} className={"leave-icon"}>
                            <img src={require("../assets/epsychelogo.png")} alt=""/>
                        </a>

                        <a href={"/"} className={"leave-icon"}>
                            <svg id="Capa_1" viewBox="0 0 551.13 551.13" width="512"><path d="m499.462 0h-378.902c-9.52 0-17.223 7.703-17.223 17.223v51.668h34.446v-34.445h344.456v482.239h-344.456v-34.446h-34.446v51.668c0 9.52 7.703 17.223 17.223 17.223h378.902c9.52 0 17.223-7.703 17.223-17.223v-516.684c0-9.52-7.704-17.223-17.223-17.223z"/><path d="m204.588 366.725 24.354 24.354 115.514-115.514-115.514-115.514-24.354 24.354 73.937 73.937h-244.079v34.446h244.08z"/></svg>
                        </a>
                    </div>
                </div>

                <div className={"chat-box"}>
                    <div className={"chat-box-header"}>
                        {_t("Wiadomości")}
                    </div>

                    <div id={"msg_list"} ref={this.messagesEnd} className={"chat-container"}>
                        {messages && messages.map((msg, key) => {
                            if (ConfigController.get("isInitiator") && msg.isInitiator) {
                                return (
                                    <div key={key} className={"self-msg"}>
                                        {msg.value.includes("is_file_s89_") ?
                                            <a className={"msg-file"} target={"_blank"} href={msg.value.split("s89_")[1]} download={"test.png"}>
                                                <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m409.785156 278.5-153.785156 153.785156-153.785156-153.785156 28.285156-28.285156 105.5 105.5v-355.714844h40v355.714844l105.5-105.5zm102.214844 193.5h-512v40h512zm0 0"/></svg>
                                                <span>{msg.value.split("/static/")[1]}</span>
                                            </a>
                                        : <span className={"chat-msg"}>{msg.value}</span>
                                        }
                                    </div>
                                )
                            } else if (!ConfigController.get("isInitiator") && !msg.isInitiator) {
                                return (
                                    <div key={key} className={"self-msg"}>
                                        {msg.value.includes("is_file_s89_") ?
                                            <a className={"msg-file"} target={"_blank"} href={msg.value.split("s89_")[1]} download={"test.png"}>
                                                <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m409.785156 278.5-153.785156 153.785156-153.785156-153.785156 28.285156-28.285156 105.5 105.5v-355.714844h40v355.714844l105.5-105.5zm102.214844 193.5h-512v40h512zm0 0"/></svg>
                                                <span>{msg.value.split("/static/")[1]}</span>
                                            </a>
                                            : <span className={"chat-msg"}>{msg.value}</span>
                                        }
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={key} className={"normal-msg"}>
                                        {msg.value.includes("is_file_s89_") ?
                                            <a className={"msg-file"} target={"_blank"} href={msg.value.split("s89_")[1]} download={"test.png"}>
                                                <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m409.785156 278.5-153.785156 153.785156-153.785156-153.785156 28.285156-28.285156 105.5 105.5v-355.714844h40v355.714844l105.5-105.5zm102.214844 193.5h-512v40h512zm0 0"/></svg>
                                                <span>{msg.value.split("/static/")[1]}</span>
                                            </a>
                                            : <span className={"chat-msg"}>{msg.value}</span>
                                        }
                                    </div>
                                )
                            }
                        })}
                    </div>

                    <div className={"chat-box-content"}>
                        <div className={"chat-type"}>
                            <input placeholder={_t("Wiadomość...")}
                                   className={"chat-msg-input"}
                                   type="text"
                                   onChange={e => this.setState({
                                       msg: e.target.value
                                   })}
                                   onKeyUp={e => {
                                       if (e.keyCode === 13) {
                                           this.sendMessage()
                                       }
                                   }}
                                   value={this.state.msg}
                            />

                            <input style={{
                                display: "none"
                            }} id={"chat-file"} type="file" onChange={(e) => this.sendFile(e)}/>

                            <button className={"chat-msg-btn-send attachment-btn"} onClick={() => {
                                document.getElementById("chat-file").click()
                            }}>
                                <img src={require("../assets/attachment.png")} alt=""/>
                            </button>

                            <button className={"chat-msg-btn-send"} onClick={() => {
                                this.sendMessage()
                            }}>
                                <img src={require("../assets/send.png")} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
