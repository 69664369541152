function Timer() {
    this.startFn = null
    this.pauseFn = null
    this.state = null
}

Timer.prototype = {
    start: function () {
        return this.startFn()
    },
    stop: function () {
        return this.pauseFn()
    },
    getTime: function () {
        const dateObj = new Date(this.state);
        const utcString = dateObj.toUTCString();
        return utcString.slice(-11, -4);
    }
}

export default Timer
