import React, { useState } from 'react'
import shortId from 'shortid'
import {_t, ConfigController, NotifyController} from "../helpers/provider";
import Panel from "./panel";

const goToRoom = (history, roomId) => {
    if (!ConfigController.get("patientName")) {
        if (window.confirm(_t("Nie podałeś imienia pacjenta. Czy na pewno chcesz kontynuować?"))) {
            redirect(history, roomId)
        }
    } else if (!ConfigController.get("patientSurname")) {
        if (window.confirm(_t("Nie podałeś nazwiska pacjenta. Czy na pewno chcesz kontynuować?"))) {
            redirect(history, roomId)
        }
    } else if (!ConfigController.get("patientEmail")) {
        if (window.confirm(_t("Nie podałeś imienia pacjenta. Wysyłka maila będzie niemożliwa. Czy na pewno chcesz kontynuować?"))) {
            redirect(history, roomId)
        }
    } else {
        redirect(history, roomId)
    }
}

const redirect = (history, roomId) => {
    NotifyController.set("Tworzę wirtualny pokój...", "success")

    const patientData = {
        name: ConfigController.get("patientName"),
        surname: ConfigController.get("patientSurname"),
        email: ConfigController.get("patientEmail"),
        roomId
    }

    fetch(ConfigController.get("API_URL") + '/savePatient',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify(patientData)
        }).then(response => response.json())
        .then(response => {
            history.push(`/admin/${roomId}`)

            NotifyController.set(`Pacjent ${patientData.name} ${patientData.surname} został przypisany do pokoju.`, "success")
        })
        .catch(error => {
            NotifyController.setScreen("Nie udało się utworzyć pokoju. Spróbuj ponownie lub wyłącz zabezpieczenia przeglądarki.")
            }
        );
}

const conf = {
    pass: "pass123"
}

export function goToRoomInput({history}) {
  let [roomId, setRoomId] = useState(shortId.generate());
  let [login, setLogin] = useState(null)
    let [pass, setPass] = useState(null)
    let [isAllowed, setIsAllowed] = useState(false)
    let [isPanel, setIsPanel] = useState(false)

    const logIn = () => {
      if (pass === conf.pass) {
          setIsAllowed(true)

          NotifyController.set("Pomyślnie zalogowano. Utwórz spotaknie.", "success")
      } else {
          NotifyController.set("Niepoprawne dane logowania.")
      }
    }

  return (
      <div className={"enter-room-wrapper"}>
          {isPanel
          ? <Panel close={setIsPanel}/>
          : <div className="enter-room-container">
                  <div className={"enter-room-logo"}>
                      <img src={require("../assets/logo.png")} alt=""/>
                  </div>

                  {!isAllowed &&
                  <div className={"login-container"}>
                      <input type="password" placeholder={"Password"} onChange={(e) => setPass(e.target.value)}/>

                      <button onClick={() => logIn()}>LOG IN</button>
                  </div>
                  }

                  {isAllowed &&
                  <div className={"login-container"}>
                      <label htmlFor="">ID wirtualnego pokoju</label>
                      <input disabled={true} type="text" value={roomId} placeholder="Room id" onChange={(event) => {
                          setRoomId(event.target.value)
                      }}
                      />

                      <label htmlFor="">{_t("Dane uczestnika")}</label>
                      <input onChange={(e) => ConfigController.set("patientName", e.target.value)} type="text" className={"ipt-small"} placeholder={_t("Imię")}/>
                      <input onChange={(e) => ConfigController.set("patientSurname", e.target.value)} type="text" className={"ipt-small"} placeholder={_t("Nazwisko")}/>
                      <input onChange={(e) => ConfigController.set("patientEmail", e.target.value)} type="text" className={"ipt-small"} placeholder={_t("E-mail")}/>

                      <label htmlFor="">Link do spotkania</label>
                      <a href={window.location.href + roomId} className={"conf-link"}>{window.location.href + roomId}</a>

                      <button onClick={() => {
                          goToRoom(history, roomId)
                      }}
                      >
                          {_t("Rozpocznij spotkanie")}
                      </button>

                      <button onClick={() => setIsPanel(true)} className={"btn-small"}>
                          {_t("Archiwum")}
                      </button>
                  </div>
                  }
              </div>
          }
      </div>
  )
}
