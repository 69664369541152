import React, { Component } from 'react';
import Video from './components/video'
import './App.css';
import './styles/video.css'
import { BrowserRouter, Route } from 'react-router-dom';
import { goToRoomInput } from './components/goToRoomInput';
import {initController} from "./helpers/provider";
import Notify from "./components/notify";
import Panel from "./components/panel";
import ScreenNotify from "./components/screenNotify";
import Loader from "./components/loader";
class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            appReady: false
        }

        initController(this)
    }
  render() {
    return (
        <React.Fragment>
            {this.state.appReady ?
                <React.Fragment>
                    <div className={"onscreen-notify"}><ScreenNotify/></div>
                    <div className={"notify-root"}><Notify/></div>

                    <BrowserRouter>
                       <React.Fragment>
                          <Route path="/" exact component={goToRoomInput}/>
                          <Route path="/:roomId" exact component={Video}/>
                          <Route path="/:admin/:roomId" exact component={Video}/>
                       </React.Fragment>
                    </BrowserRouter>
                </React.Fragment>
            : <div className={"pre-app"}><Loader/></div>
            }
        </React.Fragment>
    )
  }
}

export default App;
